import React, { useContext, useEffect, useState } from 'react';
import AccountSettingTab from './Tabs-Content/AccountSettingTab';
import './style.css';
import PasswordTab from './Tabs-Content/PasswordTab';
import ChangeEmailTap from './Tabs-Content/ChangeEmailTap';
import PaymentMethodsTab from './Tabs-Content/PaymentMethodsTab';
import SubscriptionTab from './Tabs-Content/SubscriptionTab';
import Transactions_billingsTab from './Tabs-Content/Transactions_billingsTab';
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownDivider, DropdownItem } from "flowbite-react";
import { CustomFlowbiteTheme } from "flowbite-react";
import subscriptionsService from '../../Services/subscriptionsService';
import { userIsLoginContext } from '../../Context/UserIsLoginProvider';
import userService from '../../Services/userService';

function AccountSetting() {
    const [activeTab, setActiveTab] = useState('Account Setting'); // State to track active tab

    const tabs = [
        { name: 'Account Setting', to: '', content: <AccountSettingTab />, current: true },
        { name: 'Password', to: '/password', content: <PasswordTab />, current: false },
        { name: 'Change Email', to: '/change-Email', content: <ChangeEmailTap />, current: false },
        { name: 'Payment Methods', to: '/payment-methods', content: <PaymentMethodsTab />, current: false },
        { name: 'Subscriptions', to: '/subscription', content: <SubscriptionTab />, current: false },
        { name: 'Transactions &  Billings', to: '/transactions-billings', content: <Transactions_billingsTab />, current: false },
    ];

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    document.title = "Account Setting | Streamline Your Workflow, Automate Your Success.";

    const customTheme = {
        button: {
            color: {
                primary: "bg-red-500 hover:bg-red-600", // Customize button color
            },
            focus: "outline-none", // Remove focus outline
            ring: "ring-transparent", // Remove ring outline
        },
    };
    const [subscriptions, setSubscriptions] = useState([])

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await subscriptionsService.getSubscriptionsData();
                setSubscriptions(response);
            } catch (error) {
                console.log(error);
            }
        };
        fetchSubscriptions();
    }
        , []);

     const [token, setToken] = useState(localStorage.getItem('auth'));

    const [lastSegment, setLastSegment] = useState('');
    const nav = useNavigate();
    useEffect(() => {
        const pathArray = window.location.pathname.split('/');
        const segment = pathArray[pathArray.length - 1];

        if (segment == "")
            setActiveTab("Account Setting");
        else if (segment == "password")
            setActiveTab("Password");
        else if (segment == "change-Email")
            setActiveTab("Change Email");
        else if (segment == "payment-methods")
            setActiveTab("Payment Methods");
        else if (segment == "subscription")
            setActiveTab("Subscriptions");
        else if (segment == "transactions-billings")
            setActiveTab("Transactions &  Billings");

       

    }, []);
    const { authData } = useContext(userIsLoginContext);

    if (!authData) {

    }

    useEffect(()=>{
        userService.getUserData().then((res) => {
            if (res.email_verified_at === null) {
                nav('/login');
            }
            if (res.success === false) {
                nav('/login');
            }
        }).catch((error) => {
            nav('/login');
        });
    },[])


    return (
        <div className='flex-1 overflow-auto bg-[#0d0d0d] HiddenScroll h-100 ' >
            <div className='flex lg:flex-row flex-col w-11/12 m-auto relative'>
                <div className=' lg:w-2/12 w-full pt-10'>
                    <div className="md:hidden drop">
                        {/* <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs text-base"
                            value={activeTab}
                            onChange={(e) => setActiveTab(e.target.value)}
                        >
                            {tabs.map((tab) => (
                                <option key={tab.name} value={tab.name} className='sm:text-xs text-base'>
                                    <Link to={`/Account-Setting${tab.to}`}>{tab.name}</Link>
                                </option>
                            ))}
                        </select> */}
                        <Dropdown label={activeTab} className='w-full px-4 dropDownn bg-white'>
                            <Link to='' className='bg-white'>
                                <Dropdown.Item onClick={() => { setActiveTab('Account Setting') }}>Account Setting</Dropdown.Item>
                            </Link>
                            <Link to={'password'}>
                                <Dropdown.Item onClick={() => { setActiveTab('Password') }}>Password</Dropdown.Item>
                            </Link>
                            <Link to={'change-Email'}>
                                <Dropdown.Item onClick={() => { setActiveTab('Change Email') }}>Change Email</Dropdown.Item>
                            </Link>
                            <Link to={'payment-methods'}>
                                <Dropdown.Item onClick={() => { setActiveTab('Payment Methods') }}>Payment Methods</Dropdown.Item>
                            </Link>
                            <Link to={'subscription'}>
                                <Dropdown.Item onClick={() => { setActiveTab('Subscriptions') }}>Subscriptions</Dropdown.Item>
                            </Link>
                            <Link to={'transactions-billings'}>
                                <Dropdown.Item onClick={() => { setActiveTab('Transactions &  Billings') }}>Transactions &  Billings</Dropdown.Item>
                            </Link>
                        </Dropdown>
                    </div>
                    <div className="md:block hidden">
                        <nav className="flex lg:flex-col flex-row" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <Link
                                    key={tab.name}
                                    to={`/account-Setting${tab.to}`}
                                    className={classNames(
                                        tab.name === activeTab ? ' text-white text-nowrap text-[15px] lg:text-wrap' : 'text-[#929296]',
                                        'rounded-md px-3 py-2 font-medium mb-3 text-nowrap lg:text-wrap text-[15px]'
                                    )}
                                    onClick={() => setActiveTab(tab.name)}
                                    aria-current={tab.name === activeTab ? 'page' : undefined}
                                >
                                    {tab.name}
                                </Link>
                            ))}
                        </nav>
                    </div>
                </div>

                {/* Render the content of the active tab */}
                <div className="p-2 text-white overflow-auto lg:w-10/12 w-full lg:h-[84.3vh] HiddenScroll lg:py-10 py-7">
                    {tabs.find(tab => tab.name === activeTab)?.content}
                    {/* <Outlet /> */}
                </div>
            </div>

        </div>
    );
}

export default AccountSetting;
