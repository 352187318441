import React, { useContext, useState, useMemo } from "react";
import profileImg from "../../../Icons/User Menu.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userIsLoginContext } from "../../../Context/UserIsLoginProvider";
import { toast } from "react-hot-toast";
import userService from "../../../Services/userService";
import Select from "react-select";
import countryList from "react-select-country-list";
import ButtonLoader from "../../../Components/Common/ButtonLoader";
import ConfirmDelete from "../../../Components/Common/ConfirmDelete";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

function AccountSettingTab() {
  const { authData, setAuthData, setToken } = useContext(userIsLoginContext);

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      FullName: authData?.name || "",
      email: authData?.email || "",
      CompanyName: authData?.company_name || "",
      company_address: authData?.company_address || "",
      Address: authData?.address || "",
      TAX_ID: authData?.company_tax_number || "",
      Billing_Address: authData?.billing_address || "",
      City: authData?.billing_city || "",
      Country: authData?.billing_country || "",
      State: authData?.billing_region || "",
      Zip_Code: authData?.billing_zipcode || "",
    },
    validationSchema: Yup.object().shape({
      FullName: Yup.string()
        .max(32, "Full Name must be at most 32 characters")
        .required("Please Enter Your Full Name"),
    }),

    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const sendData = {
        name: values.FullName,
        email: values.email,
        is_company: authData.is_company == "0" ? false : true,
        company_name: values.CompanyName,
        company_address: values.company_address,
        company_tax_number: values.TAX_ID,
        address: values.Address,
        tax_id: values.TAX_ID,
        billing_address: values.Billing_Address,

        billing_city: values.City,
        billing_country: values.Country,
        billing_region: values.State,
        billing_zipcode: values.Zip_Code,
      };

      try {
        const res = await userService
          .updateProfile(sendData)
          .then((res) => {
            return res;
          })
          .catch((error) => {
            console.log(error);
            setLoading1(false);
            setLoading2(false);
            setLoading3(false);
          });

        if (res.success) {
          toast.success("Updated Successfully");
          setAuthData(res.data);
          setLoading1(false);
          setLoading2(false);
          setLoading3(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [image, setImage] = useState(null);

  const handleImageChange = async (e) => {
    setImage(e.target.files[0]);
    // validate image size not more than 500kb
    if (e.target.files[0].size > 500000) {
      toast.error("Image size must not be more than 500kb");
      return;
    }

    // validate image type
    if (
      e.target.files[0].type !== "image/jpeg" &&
      e.target.files[0].type !== "image/png"
    ) {
      toast.error("Image must be in jpeg or png format");
      return;
    }

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const url = URL.createObjectURL(e.target.files[0]);
    setAuthData({ ...authData, profile_photo_url: url });
    try {
      const res = await userService
        .updateProfile(formData)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.log(error);
          setLoading1(false);
          setLoading2(false);
          setLoading3(false);
        });

      if (res.success) {
        toast.success(res.message);
        setAuthData(res.data);
        setLoading1(false);
        setLoading2(false);
        setLoading3(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [error, setError] = useState(null);
  const requiredText = "DELETE";
  const handleDeleteAccount = async () => {
    try {
      if (!confirmationText && requiredText) {
        setError("Confirmation text is required.");
        return;
      }

      if (confirmationText !== requiredText.toUpperCase()) {
        setError(`Please type "${requiredText.toUpperCase()}" to confirm.`);
        return;
      }

      setLoadingDelete(true);
      const res = await userService
        .deleteAccount()
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.log(error);
        });

      if (res.success) {
        toast.success(res.message);
        setLoadingDelete(false);
        setToken(null);
        setAuthData(null);
        localStorage.removeItem("auth");
        window.location.href = "/login";
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <ConfirmDelete
        open={showConfirmDelete}
        setOpen={setShowConfirmDelete}
        handleAction={handleDeleteAccount}
        title="Delete Account"
        loading={loadingDelete}
        confirmationText={confirmationText}
        setConfirmationText={setConfirmationText}
        requiredText={requiredText}
        error={error}
        message="Permanently remove your account and delete related contents from all  GrandAutomation products and platforms. This action cannot be undone - please proceed with caution"
      />

      {/* first section */}
      <section className="mb-7">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-7 gap-y-7 md:gap-y-0 ">
          <div className="sm:p-6 p-4 bg-[#121212] rounded-md border border-[#2a2a2a] sm:text-base text-sm">
            <div className="flex mb-5 items-center">
              <img
                src={
                  authData?.profile_photo_url
                    ? authData?.profile_photo_url
                    : profileImg
                }
                alt=""
                className="me-4 w-12 h-12 object-cover sm:w-14 sm:h-14 lg:w-16 lg:h-16 rounded-full"
              />
              <div>
                <h6 className="mb-1.5">Avatar</h6>
                <input
                  onChange={handleImageChange}
                  type="file"
                  id="file"
                  className="hidden"
                  accept="image/*"
                />
                <label
                  style={{
                    cursor: "pointer",
                  }}
                  htmlFor="file"
                  className="bg-[#4c4c4c] px-3 py-1.5 rounded font-semibold text-xs"
                >
                  Upload a new avatar
                </label>
              </div>
            </div>
            <p className=" text-[#888888] text-sm">
              This is your avatar. Click on the avatar to upload a custom one
              from your files.
            </p>
          </div>

          <div className=" bg-[#121212] rounded-md border border-[#2a2a2a] overflow-hidden sm:text-base text-sm">
            <div className="sm:p-6 p-4">
              <div className="flex">
                <label
                  htmlFor="FullName"
                  className="block font-medium leading-6 me-1"
                >
                  Full Name
                </label>
              </div>
              <div className="mt-2 relative">
                <input
                  type="text"
                  name="FullName"
                  id="FullName"
                  className={
                    formik.errors.FullName && formik.touched.FullName
                      ? "py-2 block w-full bg-[#121212] rounded-md border-0 text-[#ff0000] shadow-sm ring-1 ring-inset ring-[#ff0000] placeholder:text-[#ff0000] focus:ring-2 focus:ring-inset focus:ring-[#ff0000] text-sm sm:leading-6"
                      : "py-2 block w-full bg-[#121212] rounded-md border-0 text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm sm:leading-6"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.FullName}
                />
              </div>
              {/* // message to inform use 32 characters limit
               */}

              {formik.errors.FullName && formik.touched.FullName ? (
                <div className="mt-2 text-[#ff0000] text-sm">
                  {formik.errors.FullName}
                </div>
              ) : null}
            </div>

            <div className="flex justify-between sm:items-center items-end bg-[#0d0d0d] sm:p-6 p-4">
              <p className="text-[#888888] text-sm">
                Full Name must be at most 32 characters.
              </p>
              <ButtonLoader
                functionToCall={() => {
                  setLoading1(true);
                  formik.handleSubmit();
                }}
                classStyle="bg-[#323232] py-1 md:px-10 px-7 rounded md:text-sm text-xs font-medium"
                loading={loading1}
                text="Save"
              />
            </div>
          </div>
        </div>
      </section>

      {/* second section */}
      <section className="mb-7 sm:text-base text-sm">
        <div className=" bg-[#121212] rounded-md border border-[#2a2a2a] overflow-hidden">
          <div className="sm:p-6 p-4">
            <h6 className="mb-4">Company Information</h6>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-y-4 lg:gap-y-0 gap-x-4">
              <input
                type="text"
                name="CompanyName"
                id="CompanyName"
                placeholder="Company Name"
                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.CompanyName}
              />

              <input
                type="text"
                name="company_address"
                id="Address"
                placeholder="Company Address"
                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company_address}
              />

              <input
                type="text"
                name="TAX_ID"
                id="TAX_ID"
                placeholder="TAX ID"
                value={formik.values.TAX_ID}
                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="sm:p-6 p-4 bg-[#0d0d0d] flex justify-end">
            <ButtonLoader
              functionToCall={() => {
                setLoading2(true);
                formik.handleSubmit();
              }}
              classStyle="bg-[#323232] py-1 md:px-10 px-7 rounded md:text-sm text-xs font-medium"
              loading={loading2}
              text="Save"
            />
          </div>
        </div>
      </section>

      {/* third section */}
      <section className="mb-7">
        <div className=" bg-[#121212] rounded-md border border-[#2a2a2a] overflow-hidden">
          <div className="sm:p-6 p-4">
            <h6 className="mb-2">Billing Address</h6>
            <p className="text-[#888888] text-sm mb-4">
              This information will be used to generate invoices
            </p>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-y-4 gap-x-4">
              <div>
                <CountryDropdown
                  value={formik.values.Country}
                  className="bg-[#121212] block w-full rounded-md border-0 py-2 text-white ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-indigo-600 text-sm md:text-base sm:leading-6"
                  onChange={(val) => formik.setFieldValue("Country", val)}
                />
              </div>

              <div>
                <RegionDropdown
                  country={formik.values.Country}
                  className=" bg-[#121212] block w-full rounded-md border-0 py-2 text-white ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-indigo-600 text-sm md:text-base sm:leading-6"
                  value={formik.values.State}
                  onChange={(val) => formik.setFieldValue("State", val)}
                />
              </div>
              <input
                type="text"
                name="City"
                placeholder="City"
                id="City"
                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.City}
              />

              <input
                type="text"
                name="Billing_Address"
                id="Billing_Address"
                placeholder="Billing Address"
                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Billing_Address}
              />
              <input
                type="text"
                name="Zip_Code"
                placeholder="Zip Code"
                id="Zip_Code"
                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Zip_Code}
              />
            </div>
          </div>
          <div className="sm:p-6 p-4 bg-[#0d0d0d] flex justify-end">
            <ButtonLoader
              functionToCall={() => {
                setLoading3(true);
                formik.handleSubmit();
              }}
              classStyle="bg-[#323232] py-1 md:px-10 px-7 rounded md:text-sm text-xs font-medium"
              loading={loading3}
              text="Save"
            />
          </div>
        </div>
      </section>

      {/* forth section */}
      <section>
        <div className=" bg-[#121212] rounded-md overflow-hidden border border-[#d93035]">
          <div className="sm:p-6 p-4">
            <h6 className="mb-4">Delete Account</h6>
            <p className="text-sm text-[#888888]">
              Permanently remove your account and all its contents from the
              Grand Automation platform. <br />
              This action cannot be undone - please proceed with caution
            </p>
          </div>
          <div className="sm:p-6 p-4 bg-[#0d0d0d] flex justify-end">
            <ButtonLoader
              functionToCall={() => {
                setShowConfirmDelete(true);
              }}
              classStyle="bg-[#d93035] py-1 md:px-8 px-5 rounded md:text-sm text-xs font-medium"
              loading={loadingDelete}
              text="Delete"
            />
            {/* <button className="bg-[#d93035] text-white py-1 md:px-8 px-5 rounded md:text-sm text-xs font-medium">
              Delete
            </button> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccountSettingTab;
