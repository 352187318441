import React, { createContext, useEffect, useState } from "react";
import userService from "../Services/userService";
import axios from "axios";

export const userIsLoginContext = createContext();

function UserIsLoginProvider({ children }) {
  const [authData, setAuthData] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("auth"));
  const [refresh, setRefresh] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token2 = urlParams.get("token");

  // current path 


  useEffect(() => {
    if (token) {
      // set header
      const tokenHeader = JSON.parse(localStorage.getItem("auth")).token;
      axios.defaults.headers.common["Authorization"] = tokenHeader
        ? `Bearer ${tokenHeader}`
        : null;
      userService
        .getUserData()
        .then((res) => {
          if (res.success) {
            setAuthData(res.data);
          }
          if (res.email_verified_at === null) {
            // setAuthData(null)
            //localStorage.removeItem("auth");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setAuthData(null);
      if (!token2) localStorage.removeItem("auth");
    }
  }, [token]);

  useEffect(() => {
    userService
      .getUserData()
      .then((res) => {
        if (res.success) {
          setAuthData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);

  return (
    <userIsLoginContext.Provider
      value={{ authData, setAuthData, setToken, token, refresh, setRefresh }}
    >
      {children}
    </userIsLoginContext.Provider>
  );
}

export default UserIsLoginProvider;
