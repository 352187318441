

import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PasswordChecklist from "react-password-checklist"
import valid from '../../../images/check (4).png';
import invalid from '../../../images/close (3).png';
import ButtonLoader from '../../../Components/Common/ButtonLoader';
import userService from '../../../Services/userService';
import { toast } from 'react-hot-toast';
function PasswordTab() {

    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            CurrentPassword: '',
            NewPassword: '',
            Comfirm_Password: ''
        },
        validationSchema: Yup.object().shape({
            CurrentPassword: Yup.string().required('Please Enter Your Current Password'),
            NewPassword: Yup.string().required('Please Enter Your New Password'),
            Comfirm_Password: Yup.string().required('Please Enter Your Confirm Password')
        }),
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            setLoading(true)
            const sendData = {
                old_password: values.CurrentPassword,
                new_password: values.NewPassword,
                new_password_confirmation: values.Comfirm_Password
            };

            try {
                const res = await userService.updateProfile(sendData).then((res) => {
                    return res
                }
                ).catch((error) => {
                    console.log(error)
                    setLoading(false)

                }
                )

                if (res.success) {
                    toast.success(res.message)
                    setLoading(false)
                    resetForm()
                } else {
                    toast.error(res.error.message)
                    setLoading(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
    })
    document.title = "Password | Streamline Your Workflow, Automate Your Success.";
    return (
        <div className=''>
            <div className=' bg-[#121212] rounded-md border border-[#2a2a2a] overflow-hidden w-full'>
                <div className='sm:p-6 p-4 w-full'>
                    <h6 className='mb-4'>Change Password</h6>

                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-5'>
                        <div>
                            <input
                                type="password"
                                name="CurrentPassword"
                                id="CurrentPassword"
                                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6 sm:col-span-2"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.CurrentPassword}
                                placeholder='Enter Current Password'
                            />
                            {formik.errors.CurrentPassword && formik.touched.CurrentPassword ?

                                <div className="mt-2 text-[#ff0000] text-sm">
                                    {formik.errors.CurrentPassword}
                                </div> : null}
                        </div>
                        <div>

                            <input
                                type="password"
                                name="NewPassword"
                                id="NewPassword"
                                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.NewPassword}
                                placeholder='Enter New Password'
                            />
                            {formik.errors.NewPassword && formik.touched.NewPassword ?

                                <div className="mt-2 text-[#ff0000] text-sm">
                                    {formik.errors.NewPassword}
                                </div> : null}

                        </div>
                        <div>
                            <input
                                type="password"
                                name="Comfirm_Password"
                                id="Comfirm_Password"
                                value={formik.values.Comfirm_Password}
                                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder='Comfirm Password'
                            />
                            {formik.errors.Comfirm_Password && formik.touched.Comfirm_Password ?

                                <div className="mt-2 text-[#ff0000] text-sm">
                                    {formik.errors.Comfirm_Password}
                                </div> : null}

                        </div>
                    </div>
                </div>
                <div className='sm:p-6 p-4 bg-[#0d0d0d] flex sm:flex-row flex-col sm:justify-between sm:items-center'>

                    <div className=' text-sm text-start items-center flex flex-col justify-center'>
                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "capital", "match"]}
                            minLength={5}
                            value={formik.values.NewPassword}
                            valueAgain={formik.values.Comfirm_Password}
                            onChange={(isValid) => { }}
                            className=''
                            iconComponents={{ ValidIcon: <img src={valid} width={10} className='me-3 mt-2' />, InvalidIcon: <img src={invalid} width={10} className='me-3 mt-2' /> }}
                        />
                    </div>
                    <ButtonLoader functionToCall={formik.handleSubmit} classStyle={"bg-[#323232] py-1 md:px-10 px-7 rounded text-sm font-medium md:mt-0 mt-5"} loading={loading} text="Save" />
                </div>
            </div>
        </div>
    )
}

export default PasswordTab
