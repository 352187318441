import React, { useState, useEffect, useContext } from "react";
import { appData } from "../config";
import NavigateLoader from "../Components/Common/NavigateLoader";
import userService from "../Services/userService";
import axios from "axios";
// import { queries } from "@testing-library/react";

function Checklogin() {
  // get auth data from local storage and store to local storage again

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let redirect = urlParams.get("redirect");
  let path = urlParams.get("path");
  let app = urlParams.get("app");
  // remove / from the path

  // alert(redirect)

  const checkToken = async () => {
    try {
      const response = await userService.checkToken();
      return response; // Assuming the API returns a success message or confirmation
    } catch (error) {
      // throw new Error(error.response.data.error.message);
    }
  };

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    if (authData) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + authData.token;
      checkToken()
        .then((res) => {
          console.log(res);
          if (res && res.status === "success") {
            let url = app == "gc" ? appData[1].url : appData[0].url;

            let redirectUrl =
            url + "checkLogin?token=" + authData.token;

            if (redirect == "true") {
              redirectUrl =
              url +
                "checkLogin?token=" +
                authData.token +
                "&redirect=true";
              if (path != null || path != undefined || path != "") {
                redirectUrl =
                url +
                  "checkLogin?token=" +
                  authData.token +
                  "&redirect=true&path=" +
                  path;
              }
            }
            // redirect to the app
            // alert(redirectUrl)
            window.location.href = redirectUrl;
          } else {
            // alert('tt2')

            localStorage.removeItem("auth");
            if (redirect === "true") {
              window.location.href = "/login";
            }
            //  window.location.href = "/login";
          }
        })
        .catch((err) => {
          localStorage.removeItem("auth");
          console.log(err);
          if (redirect === "true") {
            window.location.href = "/login";
          }
        });
    } else {
      // alert('tt3')
      if (redirect === "true") {
        window.location.href = "/login";
      }
    }
  }, []);

  return (
    <div>
      <NavigateLoader text="Please wait..." />
    </div>
  );
}

export default Checklogin;
