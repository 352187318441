import React, { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import addIcon from "../../../Icons/addIcon.svg";
import PaymentIcon from "../../../images/paymentImg.svg";
import { FaXmark } from "react-icons/fa6";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdOutlineErrorOutline } from "react-icons/md";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import PaymentMethodComponent from "../../../Components/PaymentMethodComponent";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { LuTrash2 } from "react-icons/lu";
import ConfirmDelete from "../../../Components/Common/ConfirmDelete";
import paymentMethodsService from "../../../Services/PaymentMethodsService";
import { ThreeDots } from "react-loader-spinner";
import { Checkbox, Label, Radio } from "flowbite-react";
import { userIsLoginContext } from "../../../Context/UserIsLoginProvider";
import userService from "../../../Services/userService";
import toast from "react-hot-toast";
import Alert from "../../../Components/Common/Alert";

function PaymentMethodsTab() {
  const [open, setOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      card_Holder_Name: "",
    },
    validationSchema: Yup.object().shape({
      card_Holder_Name: Yup.number().required(
        "please enter card holder number"
      ),
    }),
  });

  const [customer, setCustomer] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [modal, setModal] = useState(false);

  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const getCustomer = async () => {
    await axios.get("/api/payment-methods/create").then((data) => {
      console.log(data);
      setClientSecret(data.data.clientSecret);
      setCustomer(data.data.customer_id);
    });
  };

  useEffect(() => {
    // Fetch the client secret from your Laravel backend
    getCustomer();
  }, []);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadPaymentMethods = async () => {
    setLoading(true);

    await paymentMethodsService
      .getPaymentMethods()
      .then(function (response) {
        // handle success
        setPaymentMethods(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        //// console.log(error);
      });
  };

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  document.title =
    "Payment Methods | Streamline Your Workflow, Automate Your Success.";

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const deletePaymentMethod = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [selcectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [defaultModal, setDefaultModal] = useState(false);

  const confirmDelete = () => {
    setDeleteLoading(true);
    paymentMethodsService.deletePaymentMethod(deleteId).then(() => {
      loadPaymentMethods();
      setDeleteLoading(false);
      setDeleteModal(false);
    });
  };

  const { authData, setAuthData } = useContext(userIsLoginContext);

  const [defuiltPaymentMethod, setDefuiltPaymentMethod] = useState(null);

  useEffect(() => {
    if (authData && paymentMethods) {
      const defaultPaymentMethod = paymentMethods.find(
        (paymentMethod) =>
          paymentMethod.id === authData.default_payment_method_id
      );

      if (defaultPaymentMethod) {
        const paymentMethodsCopy = paymentMethods.filter(
          (paymentMethod) =>
            paymentMethod.id !== authData.default_payment_method_id
        );
        paymentMethodsCopy.unshift(defaultPaymentMethod);

        // Only set payment methods if there is a change
        if (
          JSON.stringify(paymentMethods) !== JSON.stringify(paymentMethodsCopy)
        ) {
          setPaymentMethods(paymentMethodsCopy);
        }
      }

      setDefuiltPaymentMethod(
        paymentMethods[0]?.id || authData.default_payment_method_id
      );
      setLoading(false);
    }
  }, [authData, paymentMethods]);

  const changeDefaultPaymentMethod = async (id) => {
    setDeleteLoading(true);
    const sendData = {
      default_payment_method_id: id,
    };

    userService.updateProfile(sendData).then((res) => {
      if (res.success == true) {
        setDeleteLoading(false);
        setDefaultModal(false);
        toast.success(res.message);
        setAuthData({ ...authData, default_payment_method_id: id });
      } else {
        toast.error(res.message);
        setDefaultModal(false);
        setDeleteLoading(false);
      }
    });
  };

  return (
    <div className="">
      <ConfirmDelete
        open={deleteModal}
        setOpen={setDeleteModal}
        loading={deleteLoading}
        handleAction={confirmDelete}
        title={"Delete Payment Method"}
        message={"Are you sure you want to delete this payment method?"}
      />

      <Alert
        open={defaultModal}
        setOpen={setDefaultModal}
        title={"Set Default Payment Method"}
        message={
          "Are you sure you want to set this as your default payment method?"
        }
        loading={deleteLoading}
        handleAction={() => changeDefaultPaymentMethod(selcectedPaymentMethod)}
      />

      <div className="bg-[#121212] rounded-md border border-[#2a2a2a] overflow-hidden w-full">
        <div className="flex justify-between bg-[#121212] p-5">
          <h6>Payment Methods</h6>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setOpen(true);
              getCustomer();
            }}
          >
            <h6 className="me-3 text-sm text-[#888888]">Add Method</h6>
            <img src={addIcon} alt="" />
          </div>
        </div>
        <div className="flex flex-col items-center w-full bg-[#0d0d0d] px-2 py-6">
          {loading ? (
            <>
              <div
                className={`flex justify-center items-center z-50 transition-opacity
                   }`}
              >
                <ThreeDots color="white" height={100} width={100} />
              </div>
            </>
          ) : (
            <>
              {paymentMethods?.length === 0 && (
                <>
                  <img
                    src={PaymentIcon}
                    className="mb-5 grayscale"
                    alt="Payment Icon"
                  />
                  <p className="text-sm text-[#888888] text-center">
                    When you add your card to our system, the bank checks for
                    balance as a standard verification step. However, please
                    rest assured that no amount will be deducted from your
                    account during this process. It's simply a verification to
                    ensure the card's validity.
                  </p>
                </>
              )}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
                {paymentMethods?.map((paymentMethod) => (
                  <div key={paymentMethod.id} className="col-span-1">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-wrapper bg-[#0d0d0d] pb-6 rounded-xl border border-[#2a2a2a]">
                          <div className="flex justify-between mb-3 border-b border-[#2a2a2a] px-5 py-3">
                            <div className="p-2 rounded-xl cursor-pointer">
                              <div
                                className="flex items-center gap-2"
                                onChange={() => {
                                  setSelectedPaymentMethod(paymentMethod.id);
                                  setDefaultModal(true);
                                }}
                              >
                                <Radio
                                  id={paymentMethod.id}
                                  className="text-[#767676] text-xl"
                                  name="countries"
                                  value="USA"
                                  checked={
                                    defuiltPaymentMethod == paymentMethod.id
                                  }
                                />
                                <Label
                                  htmlFor="remember"
                                  className="text-white text-sm"
                                  for={paymentMethod.id}
                                >
                                  Set default
                                </Label>
                              </div>
                            </div>
                            {defuiltPaymentMethod !== paymentMethod.id && (
                              <div className="p-2 rounded-xl cursor-pointer">
                                <LuTrash2
                                  className="text-[#767676] text-xl"
                                  onClick={() =>
                                    deletePaymentMethod(paymentMethod.id)
                                  }
                                />
                              </div>
                            )}
                          </div>

                          <div className="px-5 md:px-10 pt-3">
                            <Cards
                              expiry={
                                paymentMethod?.paygate_data?.data?.[0]?.card
                                  ?.exp_month +
                                paymentMethod?.paygate_data?.data?.[0]?.card?.exp_year
                                  .toString()
                                  .slice(-2)
                              }
                              name={paymentMethod?.card_name}
                              number={
                                paymentMethod?.paygate_data?.data?.[0]?.card
                                  ?.last4
                              }
                              className="px-14"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {/* add method dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative bg-white z-10"
          onClose={() => setOpen(true)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[500px] w-full my-auto sm:p-4">
                  <div>
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-dark mb-3"
                      >
                        <div className="flex justify-between">
                          <h6>Add New Card</h6>
                          <FaXmark
                            onClick={() => setOpen(false)}
                            className="cursor-pointer"
                          />
                        </div>
                      </Dialog.Title>
                      <div className="mt-2 ">
                        <div className="relative">
                          <PaymentMethodComponent
                            customer={customer}
                            clientSecret={clientSecret}
                            setOpen={setOpen}
                            loadPaymentMethods={loadPaymentMethods}
                          />
                          {/* <input
                            type="text"
                            name="card_Holder_Name"
                            id="card_Holder_Name"
                            className={formik.errors.card_Holder_Name && formik.touched.card_Holder_Name ? "block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#ff0000] placeholder:text-[#ff0000] focus:ring-2 focus:ring-inset focus:ring-[#ff0000] sm:text-sm sm:leading-6 bg-transparent"
                              :
                              "block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6 bg-transparent"}

                            placeholder="card Holder Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default PaymentMethodsTab;
