import axios from "axios";

const PaymentMethodsService = {
  getPaymentMethods: async () => {
    return axios.get("/api/payment-methods");
  },

  createPaymentMethod: async (data) => {
    return axios.post("/api/payment-methods", data);
  },

  deletePaymentMethod: async (id) => {
    return axios.delete(`/api/payment-methods/${id}`);
  },

  // Other methods like changePassword, deleteUser, etc. can be added here
};

export default PaymentMethodsService;
