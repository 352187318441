import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./Pages/LandingPage/LandingPage";
import { Route, Routes, useNavigate } from "react-router-dom";
import LoginPage from "./Pages/Login/LoginPage";
import MainEntry from "./Pages/LandingPage/MainEntry/MainEntry";
import SignUp from "./Pages/Register/Register";
import OTP from "./Pages/OTP/OTP";
import WelcomingPage from "./Pages/welcomingPage/WelcomingPage";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import NavbarComponent from "./Components/Navbar/Navbar";
import UserIsLoginProvider from "./Context/UserIsLoginProvider";
import AccountSetting from "./Pages/AccountSetting/AccountSetting";
import PasswordTab from "./Pages/AccountSetting/Tabs-Content/PasswordTab";
import ChangeEmailTap from "./Pages/AccountSetting/Tabs-Content/ChangeEmailTap";
import PaymentMethodsTab from "./Pages/AccountSetting/Tabs-Content/PaymentMethodsTab";
import SubscriptionTab from "./Pages/AccountSetting/Tabs-Content/SubscriptionTab";
import Transactions_billingsTab from "./Pages/AccountSetting/Tabs-Content/Transactions_billingsTab";
import AccountSettingTab from "./Pages/AccountSetting/Tabs-Content/AccountSettingTab";
import "./App.css";
import { Toaster } from "react-hot-toast";
import ResetPassword from "./Pages/ForgetPassword/ResetPassword";
import PrivateRoute from "./PrivateRoute";
import FooterComponent from "./Components/Footer/FooterComponent";
import SSO from "./Components/SSO";
import Checklogin from "./Components/Checklogin";
import { isAuthenticated } from "./Helper/Auth";
// navigation
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import NotFound from "./Pages/NotFound";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions/TermsConditions";
import NotFoundRoute from "./NotFoundRoute";
import userService from "./Services/userService";
import axios from "axios";
import Test from "./Pages/Test";

//c
function App() {
  const toastOptions = {
    duration: 4000, // 5 seconds in milliseconds
  };
  const navigate = useNavigate();

  const isCheckLoginRoute = () => {
    const path = window.location.pathname;
    return path == "/checkLogin";
  };

  const CheckNotFound = () => {
    const path = window.location.pathname;
    return path === "/not-found";
  };

  useEffect(() => {
    if (!isCheckLoginRoute()) {
      let auth = localStorage.getItem("auth");

      if (auth) {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData ? authData.token : null;

        // Set the Authorization header for Axios requests
        axios.defaults.headers.common["Authorization"] = token
          ? `Bearer ${token}`
          : null;

        userService
          .getUserData()
          .then((res) => {
            if (res.success && res.data.email_verified_at == null) {
              // remove token
              localStorage.removeItem("auth");
            }
          })
          .catch((error) => {
            console.log("test", error);
            localStorage.removeItem("auth");
          });
      }
    }
  }, []);

  // alert(isAuthenticated)
  const authenticatedRoutes = !isAuthenticated() ? (
    <>
      <Route path="forget-password" element={<ForgetPassword />} />
      <Route path="register" element={<SignUp />} />
      <Route path="login" element={<LoginPage />} />
    </>
  ) : (
    <>
      <Route path="forget-password" element={<Navigate to="/" replace />} />
      <Route path="register" element={<Navigate to="/" replace />} />
      <Route path="login" element={<Navigate to="/" replace />} />
    </>
  );
  return (
    <>
      <Toaster toastOptions={toastOptions} />
      <UserIsLoginProvider>
        <div className="flex flex-col h-screen justify-between bg-[#121212]">
          {!isCheckLoginRoute() && <NavbarComponent />}
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route index element={<LandingPage />} />
            <Route path="test" element={<Test />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-conditions" element={<TermsConditions />} />
            <Route path="sso" element={<SSO />} />
            <Route path="not-found" element={<NotFound />} />
            <Route path="checkLogin" element={<Checklogin />} />
            <Route
              exact
              path="auth-pass-change/:token"
              element={<ResetPassword />}
            />

            <Route
              path="otp-verification"
              element={
                <PrivateRoute>
                  <OTP />
                </PrivateRoute>
              }
            />
            <Route path="otp-verification/:token" element={<OTP />} />
            {authenticatedRoutes}

            <Route
              exact
              path="auth-pass-change/:token"
              element={<ResetPassword />}
            />

            <Route
              exact
              path="Account-Setting"
              element={
                <PrivateRoute>
                  <AccountSetting />
                </PrivateRoute>
              }
            >
              <Route path="" element={<AccountSettingTab />} />
              <Route path="password" element={<PasswordTab />} />
              <Route path="change-Email" element={<ChangeEmailTap />} />
              <Route path="payment-methods" element={<PaymentMethodsTab />} />
              <Route path="subscription" element={<SubscriptionTab />} />
              <Route
                path="transactions-billings"
                element={<Transactions_billingsTab />}
              />
            </Route>
          </Routes>
          {!isCheckLoginRoute() && !CheckNotFound() && <FooterComponent />}
        </div>
      </UserIsLoginProvider>
    </>
  );
}

export default App;
