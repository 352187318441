



import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { userIsLoginContext } from '../../../Context/UserIsLoginProvider';
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import toast from 'react-hot-toast';
import userService from '../../../Services/userService';
import ButtonLoader from '../../../Components/Common/ButtonLoader';

function ChangeEmailTap() {
    const { authData, setAuthData, setToken } = useContext(userIsLoginContext);
    const [step, setStep] = useState(1);
    const [password, setPassword] = useState('');
    const [emailChange, setEmailChange] = useState("")

    const formik = useFormik({
        initialValues: {
            NewEmail: '',
            otp: ''
        }
    })

    document.title = "Change Email | Streamline Your Workflow, Automate Your Success.";

    const [passwordModal, setPasswordModal] = useState(false);
    const [loader2, setLoader2] = useState(false)

    const handleSave = async (e) => {
        e.preventDefault()

        if (step == 2) {
            setLoader2(true)
            if (formik.values.otp === '') {
                toast.error('Please enter OTP')
                return
            }
            // validate otp
            // convert to integer
            let otp = parseInt(formik.values.otp)

            const data = {
                otp: otp,
                email: emailChange
            }
            await userService.changeEmail(data).then((res) => {
                if (res.status == "success") {
                    toast.success(res.message)
                    setStep(0)
                    setLoader2(false)
                    setPasswordModal(false)
                    setEmailChange('')
                    setEmail('')
                    
                    setAuthData({ ...authData, email: emailChange })
                    return
                }
                else {
                    setLoader2(false)
                    toast.error(res.message)
                    return
                }
            }).catch((error) => {
                console.log(error)
                setLoading(false)

            })

            return
        }








        if (email === '') {
            toast.error('Please enter your new email')
            return
        }
        // validate email
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error('Please enter a valid email')
            return
        }

        setPasswordModal(true)
    }
    const [isUnique, setIsUnique] = useState(false)
    const checkEmailUnique = async (email) => {
        try {
            const res = await userService.checkEmailUnique(email).then((res) => {
                return res
            }
            ).catch((error) => {
                console.log(error)
            }
            )

            if (res.status == "success") {
                setIsUnique(true)
                return true
            }
            else {
                setIsUnique(false)
                toast.error(res.message)
                return false
            }

        } catch (error) {
            console.log(error)
        }
    }





    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')


    const validateEmail = async (value) => {

        setEmail(value)
        if (!value) {
            setErrorMessage('Email is required')
            return;
        }

        if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setErrorMessage('Email is invalid')
            return;
        }

        const data = {
            email: value
        }

        const response = await userService.checkEmailUnique(data);

        if (response.status === 'error') {
            setErrorMessage(response.message)
        } else {
            setErrorMessage('')
        }

    }



    const checkPassword = async (e) => {
        e.preventDefault()
        setLoading(true)
        const sendData = {
            password: password,
            new_email: email
        }

        try {
            const res = await userService.checkPassword(sendData).then((res) => {
                return res
            }
            ).catch((error) => {
                console.log(error)
                setLoading(false)
            }
            )

            if (res.status == "success") {
                setLoading(false)
                setPasswordModal(false)
                setEmailChange(email)
                toast.success("Password confirmed .. Please check your new email for OTP")
                setStep(2)
                return true
            }
            else {
                setLoading(false)
                toast.error(res.message)
                return false
            }

        } catch (error) {
            console.log(error)
        }
    }






    return (
        <div className=''>
            <Modal show={passwordModal} size="md" onClose={() => {
                setPasswordModal(false)
            }} popup>
                {/* <Modal.Header className="px-4">
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Confirm password</h2>
                </Modal.Header> */}

                <Modal.Body>
                    <div className="space-y-5 mt-4">
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="password" value="Please confirm your password" />
                            </div>
                            <form rm className="w-full" onSubmit={checkPassword}>
                                <TextInput value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}
                                    id="password" type="password" required />
                            </form>
                        </div>

                        <div className="w-full">
                            <Button isProcessing={loading} color={'dark'} onClick={checkPassword} >Confirm</Button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
            <div className=' bg-[#121212] rounded-md border border-[#2a2a2a] overflow-hidden w-full'>
                <div className='p-6 w-full'>
                    <h6 className='mb-4'>Change Email</h6>
                    <p className='text-sm text-[#888888] mb-7'>Current Email : <span className='text-white'>{authData?.email}</span></p>
                    <div className='grid md:grid-cols-2 grid-cols-1 gap-x-4'>
                        <input
                            type="email"
                            name="NewEmail"
                            id="NewEmail"
                            disabled={step == 2}
                            className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                            onBlur={formik.handleBlur}
                            value={email}
                            onChange={(e) => validateEmail(e.target.value)}
                            placeholder='Enter Your New Email'
                        />
                    </div>
                    {errorMessage && <p className="text-red-500 text-xs mt-1">{errorMessage}</p>}

                </div>
                {step == 2 &&
                    <div className='px-6 py-3 w-full'>
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-x-4'>
                            <input
                                type="text"
                                name="otp"
                                id="otp"
                                className="py-2 block w-full rounded-md border-0 bg-transparent text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 text-sm md:text-base sm:leading-6"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.otp}
                                placeholder='Enter OTP'
                            />
                        </div>
                    </div>

                }
                <div className='p-6 bg-[#0d0d0d] flex justify-end items-center'>
                    <ButtonLoader disabled={errorMessage} functionToCall={handleSave} classStyle={"bg-[#323232] py-1 md:px-10 px-7 rounded text-sm font-medium md:mt-0 mt-5"} loading={loader2} text="Save" />

                </div>
            </div>
        </div>
    )
}

export default ChangeEmailTap
