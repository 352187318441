

import React, { useState } from 'react'

function Test() {

    const [dates, setDates] = useState([
        { id: 1, date: new Date(2023, 3, 25) },
        { id: 2, date: new Date(2023, 4, 1) },
        { id: 3, date: new Date(2023, 4, 15) },
        { id: 4, date: new Date(2023, 4, 8) },
        { id: 5, date: new Date(2023, 3, 30) }
    ]);
    const [sortDirection, setSortDirection] = useState('ASC');
    return (
        <div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Date
                                <button onClick={() => sortDates()}>
                                    Sort
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dates.map((item) => (
                            <tr key={item.id}>
                                <td>{item.date.toDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )

    function sortDates() {
        const sortedDates = dates.sort((a, b) => {
            if (sortDirection === 'ASC') {
                return a.date - b.date;
            } else {
                return b.date - a.date;
            }
        });
        setDates([...sortedDates]);
        setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }
}

export default Test
