import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Outlet } from "react-router-dom";
import "animate.css";
import StartingPage from "./StartingPage";
import { userIsLoginContext } from "../../Context/UserIsLoginProvider";
import WelcomingPage from "../welcomingPage/WelcomingPage";
import MainEntry from "./MainEntry/MainEntry";
// useNavigate
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../Helper/Auth";
import userService from "../../Services/userService";

function LandingPage() {
  const { authData, setAuthData, setToken } = useContext(userIsLoginContext);
  const nav = useNavigate();
  console.log(authData);

  useEffect(() => {
    document.title = "Home | Streamline Your Workflow, Automate Your Success.";

    if (isAuthenticated()) {
      // get user data
      userService
        .getUserData()
        .then((res) => {
          if (res.success) {
            setAuthData(res.data);
            if (res.data.email_verified_at === null) {
              nav("/otp-verification");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <>
      <div className="bg-[#121212] flex flex-col flex-1">
        <StartingPage />
        <Outlet />
      </div>

      {authData ? <WelcomingPage /> : <MainEntry />}
    </>
  );
}

export default LandingPage;
