import React, { useState } from "react";
import { Button, Modal } from "flowbite-react";
function ProductModal({
  show,
  onClose,
  onNavigateSameWindow,
  onOpenNewWindow,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleConfirm = () => {
    if (selectedOption === "sameWindow") {
      onNavigateSameWindow();
    } else if (selectedOption === "newWindow") {
      onOpenNewWindow();
    }
    onClose();
  };

  return (
    <div>
      <Modal show={show} onClose={onClose}>
        <Modal.Header>Terms of Service</Modal.Header>
        <Modal.Body>
          <div className="card-container">
            <div
              className={`card ${
                selectedOption === "sameWindow" ? "selected" : ""
              }`}
              onClick={() => handleOptionSelect("sameWindow")}
            >
              <h3>Navigate in Same Window</h3>
            </div>
            <div
              className={`card ${
                selectedOption === "newWindow" ? "selected" : ""
              }`}
              onClick={() => handleOptionSelect("newWindow")}
            >
              <h3>Open in New Window</h3>
            </div>
          </div>
        </Modal.Body>
      </Modal>

     
    </div>
  );
}

export default ProductModal;
