import React, { useState, useEffect, useContext } from "react";
import { Footer } from "flowbite-react";
import { Link } from "react-router-dom";

function FooterComponent() {
  return (
    <Footer className="sm:px-14 px-5 py-2 bg-[#121212] border-t border-[#2a2a2a] rounded-none">
      <div className="w-full bg-[#121212] flex sm:flex-row flex-col items-center justify-between rounded-none">
        <Footer.Copyright
          href="#"
          by="GrandAutomation. All rights reserved"
          year={new Date().getFullYear()}
        />
        <div className="sm:mt-0 mt-3">
          <Link
            className="text-white me-5 font-extralight text-sm"
            to="terms-and-conditions"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Terms & Conditions
          </Link>
          <Link
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="text-white font-extralight text-sm"
            to="privacy-policy"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </Footer>
  );
}

export default FooterComponent;
