// importing products logo
import React, { useState, Fragment, useEffect, useMemo } from "react";
import TransferProduct from "../../../images/productsBorderless/transfer.svg";
import Calender from "../../../images/productsBorderless/calender.svg";
import Form from "../../../images/productsBorderless/form.svg";
import WorkFlow from "../../../images/productsBorderless/workflow.svg";
import SupportTick from "../../../images/productsBorderless/supportTick.svg";
import HRM from "../../../images/productsBorderless/HRM.svg";
import Signature from "../../../images/productsBorderless/signature.svg";
import Accounting from "../../../images/productsBorderless/accounting.svg";
import Projects from "../../../images/productsBorderless/projects.svg";
import Forms from "../../../images/productsBorderless/forms.svg";
import Inventory from "../../../images/productsBorderless/inventory.svg";
import VideoConference from "../../../images/productsBorderless/videoConference.svg";

// importing icons
import InvoiceIcon from "../../../Icons//invoiceIcon.svg";
import detailsIcon from "../../../Icons/detailsIcon.svg";
import filter_icon from "../../../Icons/filter_icon.svg";
import { FaXmark } from "react-icons/fa6";
import DateIcon from "../../../Icons/calendar.svg";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

// importing MUI components
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import { Dialog, Transition } from "@headlessui/react";
import Popover from "@mui/material/Popover";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import subscriptionsService from "../../../Services/subscriptionsService";
import { MdFrontLoader } from "react-icons/md";
import Loader from "../../../Components/Common/Loader";
import { RotatingLines, ThreeDots } from "react-loader-spinner";

import Datepicker from "react-tailwindcss-datepicker";

function Transactions_billingsTab() {
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  // products logos
  const products = [
    TransferProduct,
    Calender,
    Form,
    WorkFlow,
    SupportTick,
    HRM,
    Signature,
    Accounting,
    Projects,
    Forms,
    Inventory,
    VideoConference,
  ];

  // for dialog
  const [open, setOpen] = useState(false);

  // table rows
  const TableProduct = [
    {
      num: "GTT-000000078",
      Product: `${TransferProduct}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Failed",
    },
    {
      num: "GTT-000000078",
      Product: `${Calender}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Success",
    },
    {
      num: "GTT-000000078",
      Product: `${Form}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Failed",
    },
    {
      num: "GTT-000000078",
      Product: `${WorkFlow}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Failed",
    },
    {
      num: "GTT-000000078",
      Product: `${SupportTick}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Success",
    },
    {
      num: "GTT-000000078",
      Product: `${HRM}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Success",
    },
    {
      num: "GTT-000000078",
      Product: `${Signature}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Success",
    },
    {
      num: "GTT-000000078",
      Product: `${Accounting}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Success",
    },
    {
      num: "GTT-000000078",
      Product: `${Projects}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Success",
    },
    {
      num: "GTT-000000078",
      Product: `${Forms}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Success",
    },
    {
      num: "GTT-000000078",
      Product: `${Inventory}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Success",
    },
    {
      num: "GTT-000000078",
      Product: `${VideoConference}`,
      Date: "Mar13 2024 at 12:00 AM",
      Plan: "Plus Monthly",
      Amount: "6 USD",
      Status: "Success",
    },
  ];

  // pagination
  const [page, setPage] = useState(1);
  const productsPerPage = 5; // Number of products to display per page

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const startIndex = (page - 1) * productsPerPage;
  const endIndex = page * productsPerPage;
  const displayedProducts = TableProduct.slice(startIndex, endIndex);

  // filter dropdown
  const [rotateFilterIcon, setRotateFilterIcon] = useState(false);
  const [openFilterDropDown, setOpenFilterDropDown] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenFilterDropDown((previousOpen) => !previousOpen);
  };
  const canBeOpen = openFilterDropDown && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  // date dropdown
  const [rotateDateIcon, setRotateDateIcon] = useState(false);
  const [openDateDropDown, setOpenDateDropDown] = React.useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    setOpenDateDropDown((previousOpen) => !previousOpen);
  };
  const canBeOpen2 = openDateDropDown && Boolean(anchorEl2);
  const id2 = canBeOpen2 ? "transition-popper" : undefined;

  const shortcutsItems = [
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "Next Month",
      getValue: () => {
        const today = dayjs();
        const startOfNextMonth = today.endOf("month").add(1, "day");
        return [startOfNextMonth, startOfNextMonth.endOf("month")];
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ];

  document.title =
    "Transactions & Billings | Streamline Your Workflow, Automate Your Success.";

  const [subscriptions, setSubscriptions] = useState([]);

  const formatDate = (date) => {
    return dayjs(date).format("MMM DD, YYYY");
  };
  function getStatusLabel(status) {
    if (status === "succeeded") {
      return "Success";
    } else if (status === "refunded") {
      return "Refunded";
    } else if (status === "auto_refunded") {
      return "Auto Refunded";
    } else {
      return "Failed";
    }
  }

  const [selectedDate, setSelectedDate] = React.useState([null, null]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await subscriptionsService.getSubscriptionsData();
        // filter response?.subscriptions?.data when is_free remove
        let revamp = response?.gt_subscriptions?.data.filter(
          (obj) => !obj.is_free
        );
        setSubscriptions(revamp ?? []);
        let reformattedArray = revamp.map((obj) => {
          return {
            ...obj,
            num: obj.hid,
            Product: obj.app == "GT" ? products[0] : null,
            Date: formatDate(obj.created_at),
            Plan: obj.name,
            Amount: obj.total,
            created_at: obj.created_at,
            Status: getStatusLabel(obj.status),
          };
        });

        setSubscriptions(reformattedArray ?? []);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    };
    fetchSubscriptions();
  }, []);

  // Filtering logic
  const filteredSubscriptions = subscriptions.filter((subscription) => {
    const subscriptionDate = dayjs(subscription.created_at);
    console.log("dateRange:", dateRange);
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;

    if (startDate && endDate) {
      return subscriptionDate.isBetween(startDate, endDate);
    }
    return true;
  });
  const [transationDownload, setTransationDownload] = useState(false);

  const downloadInvoice = (transaction) => {
    setTransationDownload(transaction.id);
    subscriptionsService
      .downloadInvoice(transaction,'GT')
      .then((res) => {
        setTransationDownload(false);
      });

    // download file
  };

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedSubscriptions = useMemo(() => {
    if (!sortConfig.key) return filteredSubscriptions;

    const sortedData = [...filteredSubscriptions].sort((a, b) => {
      let valA = a[sortConfig.key];
      let valB = b[sortConfig.key];

      // Handle numeric sorting for "Amount"
      if (sortConfig.key === "Amount") {
        valA = parseFloat(valA.replace(" USD", ""));
        valB = parseFloat(valB.replace(" USD", ""));
      }
      if (valA < valB) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (valA > valB) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return sortedData;
  }, [filteredSubscriptions, sortConfig]);

  return (
    <div className="">
      <div className="flex mb-7 sm:w-1/5 w-full text-sm text-[#929296]">
        {/* <div className='flex cursor-pointer items-center me-6'
          aria-describedby={id} type="button" onClick={(event) => { handleClick(event); setRotateFilterIcon(!rotateFilterIcon) }}
        >
          <img src={filter_icon} alt="" className='me-2' />
          <h6>Filter By Product</h6>
          <IoIosArrowDown className={rotateFilterIcon ? 'text-white ms-1.5 mt-1 text-lg rotate-180 transition-all duration-300 cursor-pointer' : 'text-white ms-1.5 mt-1 text-lg rotate-0 transition-all duration-300 cursor-pointer'} />

        </div> */}

        <Datepicker
          value={dateRange}
          onChange={(newValue) => {
            setDateRange(newValue);
          }}
          showShortcuts={true}
          placeholder="Select Date Range"
          inputClassName="bg-[#121212] rounded border-[#2a2a2a] text-white placeholder:text-white focus:border-white focus:ring-0 w-full"
        />

        {/* <div className='flex cursor-pointer items-center'
          aria-describedby={id2} variant="contained" onClick={(event) => { handleClick2(event); setRotateDateIcon(!rotateDateIcon) }}
        >
          <img src={DateIcon} alt="" className='me-2' />
          <h6>Date</h6>

          <IoIosArrowDown className={rotateDateIcon ? 'text-white ms-1.5 mt-1 text-lg rotate-180 transition-all duration-300 cursor-pointer' : 'text-white ms-1.5 mt-1 text-lg rotate-0 transition-all duration-300 cursor-pointer'} />
        </div> */}
      </div>

      <div className=" text-white bg-[#0d0d0d] rounded-md border border-[#2a2a2a] overflow-hidden w-full">
        <div className="sm:flex sm:items-center bg-[#121212] p-5">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6">
              Transactions & Billings
            </h1>
          </div>
        </div>
        <div className=" flow-root bg-[#0d0d0d] p-5 px-7">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {loading ? (
              <div
                className={`flex justify-center items-center z-50 transition-opacity
                  }`}
              >
                <ThreeDots color="white" height={100} width={100} />
              </div>
            ) : (
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {filteredSubscriptions?.length === 0 ? (
                  <div className="flex justify-center w-full pt-7 mb-2 px-2">
                    <p className="md:text-xl sm:text-base text-sm text-center text-white">
                      No transactions found
                    </p>
                  </div>
                ) : (
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold "
                        >
                          Product
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          <div
                            className="flex items-center cursor-pointer   "
                            onClick={() => handleSort("Date")}
                          >
                            <h6 className="m-0">Date</h6>
                            <div className="ms-3">
                              {sortConfig.key === "Date" ? (
                                sortConfig.direction === "ascending" ? (
                                  <IoIosArrowUp className="ms-1.5 mt-1 text-lg cursor-pointer" />
                                ) : (
                                  <IoIosArrowDown className="ms-1.5 mt-1 text-lg cursor-pointer" />
                                )
                              ) : (
                                <IoIosArrowUp className="ms-1.5 mt-1 text-lg cursor-pointer" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          <div
                            className="flex items-center cursor-pointer"
                            onClick={() => handleSort("Plan")}
                          >
                            <h6 className="m-0">Description</h6>
                            <div className="ms-3">
                              {sortConfig.key === "Plan" ? (
                                sortConfig.direction === "ascending" ? (
                                  <IoIosArrowUp className="ms-1.5 mt-1 text-lg cursor-pointer" />
                                ) : (
                                  <IoIosArrowDown className="ms-1.5 mt-1 text-lg cursor-pointer" />
                                )
                              ) : (
                                <IoIosArrowUp className="ms-1.5 mt-1 text-lg cursor-pointer" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold cursor-pointer"
                          onClick={() => handleSort("Amount")}
                        >
                          <div className="flex items-center">
                            <h6 className="m-0">Amount</h6>
                            <div className="ms-3">
                              {sortConfig.key === "Amount" ? (
                                sortConfig.direction === "ascending" ? (
                                  <IoIosArrowUp className="ms-1.5 mt-1 text-lg cursor-pointer" />
                                ) : (
                                  <IoIosArrowDown className="ms-1.5 mt-1 text-lg cursor-pointer" />
                                )
                              ) : (
                                <IoIosArrowUp className="ms-1.5 mt-1 text-lg cursor-pointer" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          <div className="flex items-center">
                            <h6 className="m-0">Status</h6>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody className=" text-sm text-[#888888]">
                      {sortedSubscriptions.map((product, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-0">
                            {product.num}
                          </td>
                          <td className="whitespace-nowrap px-3 py-1">
                            <img
                              src={product.Product}
                              alt=""
                              className={
                                index === 0 ? "cursor-pointer w-28" : "w-28"
                              }
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-1 text-sm">
                            {product.Date}
                          </td>
                          <td className="whitespace-nowrap px-3 py-1 text-sm">
                            {product.Plan}
                          </td>
                          <td className="whitespace-nowrap px-3 py-1 text-sm">
                            {product.Amount}
                          </td>
                          <td className="px-5 py-1 text-sm">
                            {product.Status == "Success" ? (
                              <h6 className="text-[#00BD9D] bg-[#00BD9D] bg-opacity-10 p-1 rounded text-center">
                                {product.Status}
                              </h6>
                            ) : (
                              <h6 className="bg-[#383838] p-1 rounded text-center " >
                                {
                                  // if status Auto Refunded make it Refunded
                                  product.Status === "Auto Refunded"
                                    ? "Refunded"
                                    : product.Status
                                }
                              </h6>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-1 text-sm">
                            <div className="flex">
                              <Tooltip
                                title="view details"
                                placement="top"
                                arrow
                              >
                                <img
                                  src={detailsIcon}
                                  alt=""
                                  className="me-3 cursor-pointer"
                                  onClick={() => {
                                    setSelectedDate(product);
                                    setOpen(true);
                                  }}
                                />
                              </Tooltip>
                              {product.Status !== "Auto Refunded" &&
                                product.Status !== "Failed" && (
                                  <Tooltip
                                    title="Invoice"
                                    placement="top"
                                    arrow
                                  >
                                    {transationDownload === product.id ? (
                                      <RotatingLines
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#fff"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        ariaLabel="rotating-lines-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                      />
                                    ) : (
                                      <img
                                        src={InvoiceIcon}
                                        alt=""
                                        className="cursor-pointer"
                                        onClick={() => {
                                          downloadInvoice(product);
                                        }}
                                      />
                                    )}
                                  </Tooltip>
                                )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* pagination */}
      {/* <div className='mt-2'>
        <Pagination
          count={Math.ceil(TableProduct.length / productsPerPage)}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChangePage}
          size='medium'
          sx={{
            '& .MuiPaginationItem-root': {
              backgroundColor: '#121212',
              color: 'white', // Change text color here
              margin: 1,
              '&:hover': {
                backgroundColor: 'white', // Change background color on hover
                color: 'black', // Change text color on hover
              },
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              backgroundColor: 'white', // Change active page indicator color here
              color: 'black', // Change text color of active page here
            },
            '& .MuiPaginationItem-root.Mui-selected:hover': {
              backgroundColor: 'white', // Change background color of selected item on hover
              color: 'black', // Change text color of selected item on hover
            },
          }}
        />
      </div> */}

      {/* Dialog for details */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#222222] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[550px] sm:p-4">
                  <div>
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-white"
                      >
                        <div className="flex justify-between">
                          <h6>View Details</h6>
                          <FaXmark
                            onClick={() => setOpen(false)}
                            className="cursor-pointer"
                          />
                        </div>
                      </Dialog.Title>
                      <div className="mt-4 text-sm font-light">
                        <div className="flex justify-between py-3 border-b border-[#383838]">
                          <h6 className="text-[#929296]">Transaction ID:</h6>
                          <h6 className="text-white">{selectedDate.hid}</h6>
                        </div>

                        <div className="flex justify-between py-3 border-b border-[#383838]">
                          <h6 className="text-[#929296]">Order Number:</h6>
                          <h6 className="text-white">
                            {selectedDate.subscription_number}
                          </h6>
                        </div>

                        <div className="flex justify-between py-3 border-b border-[#383838]">
                          <h6 className="text-[#929296]">Transaction Ref:</h6>
                          <h6 className="text-white">
                            {selectedDate.trans_ref}
                          </h6>
                        </div>

                        <div className="flex justify-between py-3 border-b border-[#383838]">
                          <h6 className="text-[#929296]">Type:</h6>
                          <h6 className="text-white">{selectedDate.type}</h6>
                        </div>

                        <div className="flex justify-between py-3 border-b border-[#383838]">
                          <h6 className="text-[#929296]">Amount:</h6>
                          <h6 className="text-white">
                            {" "}
                            {selectedDate.total}
                            {selectedDate.vat_amount !== "0 USD" && (
                              <>
                                ({" "}
                                {`${selectedDate.amount} Package + ${selectedDate.vat_amount} VAT`}
                                {selectedDate.coupon &&
                                  ` - ${selectedDate.coupon} Coupon`}{" "}
                                )
                              </>
                            )}
                          </h6>
                        </div>

                        <div className="flex justify-between py-3 border-b border-[#383838]">
                          <h6 className="text-[#929296]">Payment Method:</h6>
                          <h6 className="text-white">
                            **** **** **** {selectedDate.payment_method}
                          </h6>
                        </div>

                        <div className="flex justify-between py-3 border-b border-[#383838]">
                          <h6 className="text-[#929296]">Purchased On:</h6>
                          <h6 className="text-white">
                            {formatDate(selectedDate.created_at)}
                          </h6>
                        </div>

                        <div className="flex justify-between py-3 border-b border-[#383838]">
                          <h6 className="text-[#929296]">Status:</h6>
                          <h6 className="text-[#00BD9D] bg-[#00BD9D] bg-opacity-10 p-1 rounded text-center w-16">
                            {" "}
                            {getStatusLabel(selectedDate.status)}
                          </h6>
                        </div>

                        {selectedDate.Status !== "Auto Refunded" &&
                          selectedDate.Status !== "Failed" && (
                            <div className="flex justify-between mt-3">
                              <h6 className="text-[#929296]">Invoice:</h6>
                              {transationDownload === selectedDate.id ? (
                                <RotatingLines
                                  visible={true}
                                  height="20"
                                  width="20"
                                  color="#fff"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  ariaLabel="rotating-lines-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              ) : (
                                <div
                                  className="flex justify-between items-center"
                                  onClick={() => downloadInvoice(selectedDate)}
                                >
                                  <h6 className="text-white underline cursor-pointer flex justify-between items-center">
                                    <span>Download</span>
                                    {/* <img src={InvoiceIcon} alt="Download icon" width={20} className='cursor-pointer mx-2' onClick={() => downloadInvoice(selectedDate.id)} /> */}
                                  </h6>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* dropDown for filtering */}
      <Popper
        id={id}
        open={openFilterDropDown}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{ p: 1 }}>
              <div className="rounded-lg bg-[#222222] p-4">
                <div className="text-xs text-white">
                  <h6 className=" border-b border-[#FFFFFF1A] pb-2">
                    View All
                  </h6>
                </div>
                <div className="grid grid-cols-2 mt-2">
                  {products.map((product, index) => {
                    return <img key={index} src={product} width={120} />;
                  })}
                </div>
              </div>
            </Box>
          </Fade>
        )}
      </Popper>

      {/* dropdown for date */}
      <Popper
        id={id2}
        open={openDateDropDown}
        anchorEl={anchorEl2}
        transition
        placement="bottom-end"
        sx={{ overflow: "hidden" }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{ p: 1, overflow: "hidden" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateRangePicker
                  onChange={(newValue) => {
                    setDateRange(newValue);
                  }}
                  value={dateRange}
                  slotProps={{
                    actionBar: { actions: [] },
                  }}
                  calendars={2}
                />
              </LocalizationProvider>
            </Box>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

export default Transactions_billingsTab;
