



import React, { useEffect } from 'react'
import GrandAutomationLogo from '../../images/ProductsLogo/DarkBg/Frame.svg'
import './style.css'

function StartingPage() {

    return (
        <div className='w-full h-full absolute top-0 right-0 left-0 bottom-0 bg-[#121212] flex justify-center items-center z-10 animate-StartingPage' width={500} height={500}>
            <img src={GrandAutomationLogo} alt="" className='w-52 md:w-96' />
        </div>
    )
}

export default StartingPage

