import React, { useEffect, useContext, useState } from "react";
import "./style.css";
function NotFound() {

  useEffect(() => {
    document.title = "Not Found | Streamline Your Workflow, Automate Your Success.";
  }, []);

  return (
    <div className="flex justify-center w-full flex-1">
      <section class="page_404 w-full">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 ">
              <div class="col-sm-10 col-sm-offset-1  text-center">
                <div class="four_zero_four_bg">
                </div>

                <div class="contant_box_404">
                  <p style={{
                    fontSize: "40px",
                    color: "#666",
                    fontWeight: "400",
                    lineHeight: "1.5",
                    marginBottom: "20px",

                  }}>Look like you're lost</p>

                  <p
                    style={{
                      fontSize: "16px",
                      color: "#666",
                      fontWeight: "400",
                      lineHeight: "1.5",
                      marginBottom: "20px",
                    }}
                  >
                    the page you are looking for not avaible!
                  </p>

                  <a href="" class="link_404">
                    Go to Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NotFound;
