


import React, { useEffect } from 'react'

function PrivacyPolicy() {

    useEffect(() => {
        document.title = "Privacy Policy | Streamline Your Workflow, Automate Your Success.";
    }, []);

    return (
        <div>
            <section className='mt-14 mb-20'>
                <h1 className='text-center font-bold text-white text-3xl mb-16'>Privacy Policy</h1>
                <div className=" text-white lg:w-[62.2395833333vw] mx-[auto] sm:w-[87%] w-[87%]">
                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Introduction</h5>
                        <p className='font-thin'>At Grand Automation, your privacy is of great importance to us. This policy describes the types of
                            information we may collect from you or that you may provide when you visit the website and our
                            practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Information Collection and Use</h5>
                        <p className='font-thin'>We collect several types of information from and about users of our services, including information
                            that can identify you such as name, email address, and phone number.</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Cookies and Tracking</h5>
                        <p className='font-thin'>We use cookies and similar tracking technologies to track the activity on our service and hold certain
                            information.</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Data Security</h5>
                        <p className='font-thin'>We have implemented measures designed to secure your personal information from accidental loss
                            and from unauthorized access, use, alteration, and disclosure.</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Changes to This Privacy Policy</h5>
                        <p className='font-thin'>This Privacy Policy may change from time to time. We will notify you of any changes by posting the
                            new Privacy Policy on this page.</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Contact Us</h5>
                        <p className='font-thin'>If you have any questions about these Terms or our Privacy Policy, please contact us at
                            <a href='https://support.grandautomation.io/login' target='_blank' className='ms-2 text-decoration-underline'>support.grandautomation.io.</a>
                        </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PrivacyPolicy
