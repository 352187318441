


import React, { useEffect } from 'react'

function TermsConditions() {
    useEffect(() => {
        document.title = "Terms Conditions | Streamline Your Workflow, Automate Your Success.";
    }, []);

    return (
        <div>
            <section className='my-14'>
                <h1 className='text-center font-bold text-white text-3xl mb-16'>Terms & Conditions</h1>
                <div className="text-white lg:w-[62.2395833333vw] mx-[auto] sm:w-[87%] w-[87%]">

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Introduction</h5>
                        <p className='font-thin'>Welcome to Grand Automation. By accessing our website and using our services, you agree to
                            comply with and be bound by the following terms and conditions.</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Accounts</h5>
                        <p className='font-thin'>You are responsible for maintaining the security of your account and password. Grand Automation
                            cannot and will not be liable for any loss or damage from your failure to comply with this security
                            obligation</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Use of Service</h5>
                        <p className='font-thin'>You may use our services only as permitted by law. You may not misuse our services in any way.</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Intellectual Property</h5>
                        <p className='font-thin'>All contents of the site are the exclusive property of Grand Automation or its licensors..</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Service Availability</h5>
                        <p className='font-thin'>We aim to provide the services 24/7, however, we are not liable if the website is unavailable at any
                            time</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Termination</h5>
                        <p className='font-thin'>We may terminate your access to the services without cause or notice, which may result in the
                            forfeiture and destruction of all information associated with your account.</p>
                    </div>

                    <div className='mb-14'>
                        <h5 className='font-bold mb-1 text-xl'>Limitation of Liability</h5>
                        <p className='font-thin'>In no event will Grand Automation, nor its directors, employees, partners, suppliers, or affiliates,
                            be liable for any indirect, incidental, special, consequential or punitive damages, including without
                            limitation, loss of profits, data, use, goodwill, or other intangible losses.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TermsConditions
