import React, { useState, useEffect, useContext } from "react";
import { Navbar } from "flowbite-react";
import logo from "../../images/Logos02.png";
import logoSidebar from "../../images/Logo01.png";
import { Link, useNavigate } from "react-router-dom";
import ProfileIcon from "../../Icons/User Menu.svg";
import SidePanelIcon from "../../Icons/icon-park-outline_application-menu.svg";
import SupportIcon from "../../Icons/radix-icons_external-link.svg";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "animate.css";
import { userIsLoginContext } from "../../Context/UserIsLoginProvider";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

// Import products images
import TransferProduct from "../../images/ProductsLogo/whiteBG/TransferWhiteBg.svg";
import Calender from "../../images/ProductsLogo/whiteBG/calenderWhiteBg.svg";
import Form from "../../images/ProductsLogo/whiteBG/Property 1=G-Form.svg";
import WorkFlow from "../../images/ProductsLogo/whiteBG/Property 1=G-Workflow.svg";
import SupportTick from "../../images/ProductsLogo/whiteBG/Property 1=G-Support Tick.svg";
import HRM from "../../images/ProductsLogo/whiteBG/Property 1=G-HRM.svg";
import Signature from "../../images/ProductsLogo/whiteBG/Property 1=G-Signature.svg";
import Accounting from "../../images/ProductsLogo/whiteBG/Property 1=G-Accounting.svg";
import Projects from "../../images/ProductsLogo/whiteBG/Property 1=G-Projects.svg";
import Forms from "../../images/ProductsLogo/whiteBG/Property 1=G-Forms.svg";
import Inventory from "../../images/ProductsLogo/whiteBG/Property 1=G-Inventory.svg";
import VideoConference from "../../images/ProductsLogo/whiteBG/Property 1=G-Video Conference.svg";
import ProductModal from "../../Components/Common/ProductModa";
import NavigateLoader from "../../Components/Common/NavigateLoader";
import ssoService from "../../Services/ssoService";
import toast from "react-hot-toast";
// import icons
import settingsIcon from "../../Icons/settings icon.svg";
import logoutIcon from "../../Icons/logout icon.svg";
import { appData } from "../../config"; // Import app data from config file
import { CgMenu } from "react-icons/cg";
import { HiMiniXMark } from "react-icons/hi2";

function NavbarComponent() {
  // products logos
  const [loading, setLoading] = useState(false);

  // products logos
  const products = [
    { image: TransferProduct, app: appData[0], available: true },
    { image: Calender, app: appData[1] },
    { image: Form, app: appData[2] },
    { image: WorkFlow, app: appData[3] },
    { image: SupportTick, app: appData[4] },
    { image: HRM, app: appData[5] },
    { image: Signature, app: appData[6] },
    { image: Accounting, app: appData[7] },
    { image: Projects, app: appData[8] },
    { image: Forms, app: appData[9] },
    { image: Inventory, app: appData[10] },
    { image: VideoConference, app: appData[11] },
  ];
  useEffect(() => {
    document.title =
      "Welcoming Page | Streamline Your Workflow, Automate Your Success.";
  }, []);
  const getFirstName = (name) => {
    if (!name) return "loading...";
    return name.split(" ")[0];
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [textLoader, setTextLoader] = useState("loading...");

  const handleProductClick = async (app) => {
    // open app in new tab with token from local storage

    setLoading(true);
    setSelectedApp(app);
    console.log(app);

    // get token from local storage
    const User = JSON.parse(localStorage.getItem("auth"));
    if (!User) {
      toast.error("You are not logged in");
      setLoading(false);
      return;
    }

    let redirectUrl =
      app.url + "checkLogin?token=" + User.token + "&redirect=true&path=";

    // setTextLoader('Redirecting to ' + app['shortName'] + ' App...') after 2 seconds

    setTimeout(() => {
      setTextLoader("Redirecting to " + app["name"] + " App...");
      window.location.href = redirectUrl;
      // if (newTab) {
      //     newTab.focus();
      // } else {
      //     window.location.href = redirectUrl; // Fallback if new tab was blocked
      // }
      setLoading(false);
    }, 1000);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const { authData, setAuthData } = useContext(userIsLoginContext);

  console.log(authData);

  // offcanvas
  const [open, setOpen] = useState(false);

  // navLinks
  const [openNavLinks, setOpenNavLinks] = useState(false);

  // dropDown Menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDropdown = Boolean(anchorEl);
  const id = openDropdown ? "simple-popover" : undefined;

  const navigate = useNavigate();
  const RemoveToken = async () => {
    localStorage.removeItem("auth");
    setAuthData(null);
    await ssoService
      .logout()
      .then((res) => {
        console.log(res);
        toast.success("You 're now logged out. See you soon");
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });

    // toast.success("You 're now logged out. See you soon");
    // navigate('/');
    // const targetIframe = document.getElementById('targetIframe').contentWindow;
    // targetIframe.postMessage('logout', 'https://grandtransfer-staging.vercel.app/');
  };

  useEffect(() => {
    //check auth in local storage
    const authData = JSON.parse(localStorage.getItem("auth"));
    if (!authData) {
      setAuthData(null);
    }
  }, []);

  const signUp = () => {
    // navigate('/register');
    // remove token from local storage

    // alert('Please login to access this page')

    let auth = localStorage.getItem("auth");
    // alert(JSON.parse(auth).email_verified)
    if (auth) {
      if (authData && !authData?.email_verified_at) {
        localStorage.removeItem("auth");
        setAuthData(null);
      }
    }
  };

  return (
    <div>
      <div className="bg-[#121212] border-b border-[#2a2a2a] sm:px-0 px-4 flex items-center justify-center">
        {loading && <NavigateLoader text={textLoader} />}

        <div className="bg-[#121212] py-5 px-0 m-0 w-11/12 flex items-center justify-between">
          <Link to="/" onClick={signUp} className="">
            <img src={logo} className=" h-7 sm:h-6" alt="Flowbite React Logo" />
          </Link>

          <div className="flex items-center">
            <div className="m-0 sm:flex hidden">
              <a
                href=" https://support.grandautomation.io/login"
                className="text-white font-normal flex items-center me-5"
                target="_blank"
              >
                SUPPORT
                <img src={SupportIcon} alt="" className="ms-3 mt-0.5" />
              </a>

              {!(authData && authData?.email_verified_at) ? (
                <>
                  <Link
                    to=""
                    onClick={() => {
                      // window location to login page
                      window.location.href = "/login";
                      signUp();
                    }}
                    className="text-white font-normal flex items-center me-5"
                  >
                    LOGIN
                  </Link>
                  <Link
                    onClick={() => {
                      window.location.href = "/register";
                      signUp();
                    }}
                    to=""
                    className="text-white font-normal flex items-center"
                  >
                    SIGN UP
                  </Link>
                </>
              ) : (
                <img
                  src={
                    authData?.profile_photo_url
                      ? authData?.profile_photo_url
                      : ProfileIcon
                  }
                  alt=""
                  aria-haspopup="true"
                  onClick={handleClick}
                  className="cursor-pointer rounded-full object-cover w-7 h-7 me-4"
                />
              )}
            </div>

            <div className="flex items-center">
              {authData && authData?.email_verified_at && (
                <div className="flex items-center cursor-pointer">
                  <img
                    src={SidePanelIcon}
                    alt=""
                    className=""
                    id="fade-button"
                    onClick={() => setOpen(true)}
                  />
                </div>
              )}

              <CgMenu
                className="sm:hidden block text-[#6b7280] text-[27px] ms-5"
                onClick={() => {
                  setOpenNavLinks(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* dropDown Menu  */}
      <div>
        <Popover
          id={id}
          open={openDropdown}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ marginTop: 1 }}
        >
          <Typography>
            <div className=" text-sm p-5 pe-4">
              <div className=" ms-1 mb-1">
                <p
                  className="pe-6 mb-2 me-3 font-size-4 font-bold"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Welcome,{" "}
                  {authData?.name.length > 11
                    ? authData?.name.substring(0, 11) + "..."
                    : authData?.name}
                  !
                </p>

                <p>{authData?.email}</p>
              </div>
              <div className="border-b-2 border-gray-200 my-2"></div>
              <div className="mt-4">
                <Link
                  to="/Account-Setting"
                  className="flex pe-6 mb-1 mt-2"
                  onClick={() => {
                    handleClose();
                    setOpenNavLinks(false);
                  }}
                >
                  <img src={settingsIcon} alt="" className="me-3" />
                  <span>Account Settings</span>
                </Link>
                <div
                  className="flex cursor-pointer mt-5"
                  onClick={() => {
                    RemoveToken();
                    handleClose();
                    setOpenNavLinks(false);
                  }}
                >
                  <img src={logoutIcon} alt="" className="me-3" />
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </Typography>
        </Popover>
      </div>

      {/* offcanvas */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div
              className={
                open
                  ? "absolute inset-0 animate-opacityAnimation-start overflow-hidden"
                  : "absolute inset-0 overflow-hidden"
              }
            >
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto offcanvansWidth">
                    <div className="flex h-full flex-col overflow-auto bg-white py-5 shadow-xl sm:rounded-l-3xl">
                      <div className="relative flex-1 sm:px-7 px-5 flex flex-col justify-between">
                        <div>
                          <div className="flex items-center">
                            <HiMiniXMark
                              className="text-3xl sm:hidden block"
                              onClick={() => {
                                setOpen(false);
                              }}
                            />
                            <div className="flex justify-center w-full">
                              <img src={logoSidebar} alt="" width={90} />
                            </div>
                          </div>

                          <div className="flex flex-wrap justify-center gap-x-3 my-5">
                            {products.map((product, index) => (
                              <img
                                key={index}
                                src={product.image}
                                alt=""
                                className={
                                  product?.available // Only set cursor-pointer if available
                                    ? "cursor-pointer"
                                    : "opacity-50 cursor-default" // Indicate unavailable with opacity and cursor
                                }
                                onClick={
                                  product?.available
                                    ? () => handleProductClick(product.app)
                                    : null
                                } // Prevent click if unavailable
                              />
                            ))}
                          </div>
                        </div>

                        <div>
                          <p className="mb-4 font-[350]">
                            We try to provide a unique experience to meet your
                            business needs. So feel free to contact us to hear
                            suggestions
                          </p>
                          <a
                            href="mailto:support@grandautomation.io"
                            className="bg-black text-white font-semibold w-full text-center rounded-md py-2 inline-block text-decoration-none"
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* navLinks */}
      <Transition.Root show={openNavLinks} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 w-full p-0"
          onClose={setOpenNavLinks}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div
              className={
                openNavLinks
                  ? "absolute inset-0 animate-opacityAnimation-start overflow-hidden"
                  : "absolute inset-0 overflow-hidden"
              }
            >
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto offcanvansWidth2">
                    <div className="flex h-full flex-col overflow-auto bg-[#121212] text-white p-7 shadow-xl">
                      <div className="flex justify-between">
                        <HiMiniXMark
                          className="text-3xl"
                          onClick={() => {
                            setOpenNavLinks(false);
                          }}
                        />
                        <Link
                          to="/"
                          onClick={signUp}
                          className="decoration-0 border-0 outline-0"
                        >
                          <img
                            src={logo}
                            className=" h-7 sm:h-6"
                            alt="Flowbite React Logo"
                          />
                        </Link>
                      </div>
                      <div className="mt-12 flex flex-col justify-between h-full">
                        <div>
                          {!(authData && authData?.email_verified_at) ? (
                            <>
                              <a
                                href=" https://support.grandautomation.io/login"
                                className="text-white font-normal flex items-center border-b border-white pb-5"
                                target="_blank"
                              >
                                SUPPORT
                                <img
                                  src={SupportIcon}
                                  alt=""
                                  className="ms-3 mt-0.5"
                                />
                              </a>
                              <Link
                                to="/login"
                                onClick={() => {
                                  signUp();
                                  setOpenNavLinks(false);
                                }}
                                className="text-white font-normal flex items-center border-b border-white pb-5 mt-5"
                              >
                                LOGIN
                              </Link>
                              <Link
                                onClick={() => {
                                  signUp();
                                  setOpenNavLinks(false);
                                }}
                                to="/register"
                                className="text-white font-normal flex items-center border-b border-white pb-5 mt-5"
                              >
                                SIGN UP
                              </Link>
                            </>
                          ) : (
                            <div className=" text-sm">
                              <div className=" ms-1 mb-12">
                                <p className=" mb-2 text-xl font-bold">
                                  Welcome,{" "}
                                  {authData?.name.length > 20
                                    ? authData?.name.substring(0, 20) + "..."
                                    : authData?.name}
                                  !
                                </p>

                                <p className="text-[#848383] font-bold">
                                  {authData?.email}
                                </p>
                              </div>
                              {/* <hr className='text-white mt-4 mb-10'/> */}
                              <div className="mt-4">
                                <Link
                                  to="/Account-Setting"
                                  className="flex pe-6 mb-1 mt-2 border-b border-[#696868] pb-5"
                                  onClick={() => {
                                    handleClose();
                                    setOpenNavLinks(false);
                                  }}
                                >
                                  <img
                                    src={settingsIcon}
                                    alt=""
                                    className="me-3"
                                  />
                                  <span>ACCOUNT SETTINGS</span>
                                </Link>
                                <div
                                  className="flex cursor-pointer mt-5 border-b border-[#696868] pb-5"
                                  onClick={() => {
                                    RemoveToken();
                                    handleClose();
                                    setOpenNavLinks(false);
                                  }}
                                >
                                  <img
                                    src={logoutIcon}
                                    alt=""
                                    className="me-3"
                                  />
                                  <span>LOGOUT</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <button className="bg-[#313131] text-white font-semibold w-full text-center rounded-md py-2">
                          Contact Us
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default NavbarComponent;
