import axios from "axios";

const userService = {
  getUserData: async (token) => {
    try {
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      const response = await axios.get("/api/user");
      return response.data; // Assuming the API returns user data
    } catch (error) {
      console.log("error", "tre");
      let currentPath = window.location.pathname;

      if (
        (currentPath !== "/checkLogin" &&
          currentPath !== "/sso" &&
          localStorage.getItem("auth")) ||
        token
      ) {
        localStorage.removeItem("auth");

        window.location.href = "/login";
      }
      throw new Error(error.response.data.message);
    }
  },

  updateProfile: async (userData) => {
    try {
      // send as form data
      const response = await axios.post("/api/user", userData);
      return response.data; // Assuming the API returns updated user data
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  resetPassword: async (email) => {
    try {
      const data = {
        email: email.email.toString(),
      };
      const response = await axios.post("/api/reset-password", data);
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.error.message);
    }
  },
  updatePassword: async (data) => {
    try {
      const sendData = {
        token: data.token,
        password: data.password,
      };
      const response = await axios.post("/api/update-password", sendData);
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.error.message);
    }
  },

  verifyEmail: async (otp) => {
    try {
      const response = await axios.post("/api/verify-email", { otp });
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.error.message);
    }
  },

  resendOtp: async () => {
    try {
      const response = await axios.post("/api/resend-verify-email-otp");
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  checkToken: async () => {
    try {
      const response = await axios.get("/api/check-token");
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  verifyPasswordOtp: async (data) => {
    try {
      const response = await axios.post("/api/reset-password-verify-otp", data);
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  checkPassword: async (data) => {
    try {
      const response = await axios.post("/api/check-password", data);
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  changeEmail: async (data) => {
    try {
      const response = await axios.post("/api/change-email-confirm", data);
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  checkEmailUnique: async (data) => {
    try {
      const response = await axios.post("/api/check-email", data);
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  deleteAccount: async (data) => {
    try {
      const response = await axios.post("/api/delete-account", data);
      return response.data; // Assuming the API returns a success message or confirmation
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  // Other methods like changePassword, deleteUser, etc. can be added here
};

export default userService;
