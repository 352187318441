import React, { useEffect, useContext, useState } from "react";

// Import products images
import TransferProduct from "../../images/ProductsLogo/DarkBg/Transfer.svg";
import Calender from "../../images/ProductsLogo/DarkBg/Calender.svg";
import Form from "../../images/ProductsLogo/DarkBg/Form.svg";
import WorkFlow from "../../images/ProductsLogo/DarkBg/Workflow.svg";
import SupportTick from "../../images/ProductsLogo/DarkBg/Support Tick.svg";
import HRM from "../../images/ProductsLogo/DarkBg/HRM.svg";
import Signature from "../../images/ProductsLogo/DarkBg/signature.svg";
import Accounting from "../../images/ProductsLogo/DarkBg/Accounting.svg";
import Projects from "../../images/ProductsLogo/DarkBg/Projects.svg";
import Forms from "../../images/ProductsLogo/DarkBg/Forms.svg";
import Inventory from "../../images/ProductsLogo/DarkBg/inventory.svg";
import VideoConference from "../../images/ProductsLogo/DarkBg/video conference.svg";
import { appData } from "../../config"; // Import app data from config file

import "./Style.css";
import { userIsLoginContext } from "../../Context/UserIsLoginProvider";
import ProductModal from "../../Components/Common/ProductModa";
import NavigateLoader from "../../Components/Common/NavigateLoader";
import ssoService from "../../Services/ssoService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function WelcomingPage() {
  const { authData, setAuthData, setToken } = useContext(userIsLoginContext);
  const [loading, setLoading] = useState(false);

  // products logos
  const products = [
    { image: TransferProduct, app: appData[0], avaliable: true },
    { image: Calender, app: appData[1] },
    { image: Form, app: appData[2] },
    { image: WorkFlow, app: appData[3] },
    { image: SupportTick, app: appData[4] },
    { image: HRM, app: appData[5] },
    { image: Signature, app: appData[6] },
    { image: Accounting, app: appData[7] },
    { image: Projects, app: appData[8] },
    { image: Forms, app: appData[9] },
    { image: Inventory, app: appData[10] },
    { image: VideoConference, app: appData[11] },
  ];
  useEffect(() => {
    document.title = "Home | Streamline Your Workflow, Automate Your Success.";
  }, []);
  const getFirstName = (name) => {
    if (!name) return "loading...";
    return name.split(" ")[0];
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [textLoader, setTextLoader] = useState("loading...");

  const redirect = (url) => {
    window.open(url, "_blank").focus();
  };

  const [url, setUrl] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  const handleProductClick = async (app) => {
    // open app in new tab with token from local storage

    setLoading(true);
    setSelectedApp(app);
    console.log(app);

    // get token from local storage
    const User = JSON.parse(localStorage.getItem("auth"));
    if (!User) {
      toast.error("You are not logged in");
      setLoading(false);
      return;
    }

    let redirectUrl =
      app.url + "checkLogin?token=" + User.token + "&redirect=true&path=";

    // setTextLoader('Redirecting to ' + app['shortName'] + ' App...') after 2 seconds

    setTimeout(() => {
      setTextLoader("Redirecting to " + app["name"] + " App...");
      window.location.href = redirectUrl;
      // if (newTab) {
      //     newTab.focus();
      // } else {
      //     window.location.href = redirectUrl; // Fallback if new tab was blocked
      // }
      setLoading(false);
    }, 1000);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // useEffect(() => {
  //     if (url) {
  //         redirect(url);
  //     }
  // }, [url]);

  const nav = useNavigate();

  const redirectToUrl = (redirectUrl) => {
    if (redirectUrl) {
      const newWindow = (window.location.href = redirectUrl);
      if (newWindow) newWindow.opener = null;
    }
  };
  return (
    <div className="bg-[#121212] p-5 flex items-center justify-center h-full">
      {loading && <NavigateLoader text={textLoader} />}
      {/* <button onClick={() => handleProductClick(products[0].app)}>
                Open Link
            </button> */}
      <div className="flex flex-col items-center text-white">
        <h1 className="lg:text-4xl text-3xl mb-6 font-semibold">
          WELCOME! {getFirstName(authData?.name)}
        </h1>
        <h6 className="mb-6 font-extralight text-lg">Explore Our Products</h6>

        <div class="mouse_scroll mb-6">
          <div>
            <span class="m_scroll_arrows unu"></span>
            <span class="m_scroll_arrows trei"></span>
          </div>
        </div>

        <div className="grid md:grid-cols-6 sm:grid-cols-4 grid-cols-2 gap-x-3 gap-y-1">
          {products.map((product, index) => (
            <a
              key={index}
              onClick={() => {
                if (product?.avaliable) handleProductClick(product.app);
              }}
              className={
                product?.avaliable
                  ? "cursor-pointer lg:w-40 md:w-32 sm:w-28 w-32"
                  : "lg:w-40 md:w-32 sm:w-28 w-32 opacity-50 cursor-default"
              }
            >
              <img
                src={product.image}
                className={
                  index === 0
                    ? "lg:w-40 md:w-32 sm:w-28 w-32"
                    : "lg:w-40 md:w-32 sm:w-28 w-32"
                }
                alt={product.name}
              />
            </a>
          ))}
        </div>
      </div>
      <ProductModal show={showModal} onClose={handleCloseModal} />
    </div>
  );
}

export default WelcomingPage;
