import React, { useState, useEffect, useContext } from "react";
import NavigateLoader from "./Common/NavigateLoader";

function SSO() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  let redirect = urlParams.get("redirect");
  //

  useEffect(() => {
    if (token) {
      localStorage.setItem(
        "auth",
        JSON.stringify({ token, email_verified: true })
      );
      // if redirect is true, redirect to the app
      if (
        redirect &&
        redirect.toLowerCase() == "true" &&
        urlParams.get("url")
      ) {
        window.location.href =
          urlParams.get("url") +
          "checkLogin?token=" +
          token +
          "&redirect=true" +
          "&redirectPage=" +
          urlParams.get("redirectPage");
      }
    }
  }, [token]);

  return (
    <div>
      <NavigateLoader text="Please wait..." />
    </div>
  );
}

export default SSO;
