



import React, { Fragment, useRef, useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { MdOutlineErrorOutline } from "react-icons/md";
import GrandAutomationLogo from '../../images/ProductsLogo/DarkBg/Frame.svg'
import { Link, useNavigate } from 'react-router-dom';
import { userIsLoginContext } from '../../Context/UserIsLoginProvider';
import authService from '../../Services/authService';
import ButtonLoader from '../../Components/Common/ButtonLoader';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import userService from '../../Services/userService';
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

function LoginPage() {

    useEffect(() => {
        document.title = "Login | Streamline Your Workflow, Automate Your Success.";
    }, []);

    const { authData, setAuthData, setToken } = useContext(userIsLoginContext);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [showLogin, setShowLogin] = useState(false)
    const [continueLogin, setContinueLogin] = useState(false)



    const formik = useFormik({

        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('this is an inavlid email').required('Please Enter Your Email'),
            password: Yup.string().required('Please Enter Your password')
        })
        ,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                setLoading(true)
                // convert email and password to string
                const sendData = {
                    email: values.email.toString(), // Use toString() instead of ToString()
                    password: values.password
                };


                // if continue login is true add to the sendData
                if (continueLogin) {
                    sendData.continue_login = true;
                }


                // Call the register method from the authService
                const response = await authService.login(sendData)


                if (response.success) {
                    if (response.data.login) {
                        setShowLogin(true)
                        return;
                    }
                    // add email_verified to the response data true 
                    response.data.email_verified = true
                    localStorage.setItem('auth', JSON.stringify(response.data))
                    toast.success("Welcome back! We've missed you.")
                    setToken(response.data.token)
                    // get user data


                    await userService.getUserData(response.data.token).then((res) => {

                        if (res.success) {

                            setAuthData(res.data)
                        }
                    }
                    )
                    setLoading(false)
                    navigate('/')
                    resetForm();

                } else {
                    toast.error("Either your email or password may be incorrect. Please verify your details and attempt to log in again.")
                    setLoading(false)
                }


                // Reset the form after successful submission
            } catch (error) {
                setLoading(false)
                console.error('Error:', error);
            } finally {
                // Set submitting state to false after submission
                setSubmitting(false);
            }
        },
    })

    const cancelButtonRef = useRef(null)


    useEffect(() => {
        if (authData) {
            navigate('/')
        }
    }, [authData])


    const onContinue = () => {
        // submit the form
        setContinueLogin(true)
        setShowLogin(false)
        formik.handleSubmit()

    }
    const onCancel = () => {
        setShowLogin(false)
        setLoading(false)
    }

    return (
        <div className='animate__fadeIn animate__animated animate__faster text-white h-full flex flex-col justify-center items-center md:px-0 px-5 bg-[#121212]'>
            <Transition.Root show={showLogin} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onCancel}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                        </div> */}
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                You are login with same data on another device
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    If you continue, you will be logged out of the other device.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-[#4c4c4c] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                            onClick={() => onContinue()}
                                        >
                                            Continue
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={() => onCancel()}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <form onSubmit={formik.handleSubmit}>

                <div className='flex sm:flex-row flex-col-reverse justify-between items-center'>
                    <div className='md:me-44 me-28'>
                        <div className='md:text-xl text-base font-semibold mb-1'>
                            LOGIN
                        </div>
                        <p className='text-sm font-extralight'>One account for all our products</p>
                    </div>
                    <img src={GrandAutomationLogo} alt="" className='hidden sm:block md:w-40 w-28 sm:mb-0 mb-10' />
                </div>

                <div className=''>
                    <div className='w-full mt-10'>
                        <div className='flex'>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 me-1">
                                Email Address
                            </label>
                            <span className='text-[#ff0000]'>*</span>
                        </div>
                        <div className="mt-2 relative">
                            <input
                                type="text"
                                name="email"
                                id="email"
                                className={formik.errors.email && formik.touched.email ? "block w-full bg-[#121212] rounded-md border-0 text-[#ff0000] shadow-sm ring-1 ring-inset ring-[#ff0000] placeholder:text-[#ff0000] focus:ring-2 focus:ring-inset focus:ring-[#ff0000] sm:text-sm sm:leading-6 py-2" :

                                    "block w-full bg-[#121212] rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 sm:text-sm sm:leading-6"
                                }
                                placeholder="Enter Your Email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.email && formik.touched.email && <MdOutlineErrorOutline className='text-[#ff0000] text-xl absolute right-2.5 top-2.5' />}
                        </div>

                        {formik.errors.email && formik.touched.email ?

                            <div className="mt-2 text-[#ff0000] text-sm">
                                {formik.errors.email}
                            </div> : null}
                    </div>
                </div>

                <div className='mt-4'>
                    <div className='flex'>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 me-1">
                            Password
                        </label>
                        <span className='text-[#ff0000]'>*</span>
                    </div>

                    <div className="my-2 relative">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            className={formik.errors.password && formik.touched.password ? "block w-full bg-[#121212] rounded-md border-0 text-[#ff0000] shadow-sm ring-1 ring-inset ring-[#ff0000] placeholder:text-[#ff0000] focus:ring-2 focus:ring-inset focus:ring-[#ff0000] sm:text-sm sm:leading-6 py-2" :

                                "block w-full bg-[#121212] rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#4c4c4c] placeholder:text-[#4c4c4c] focus:ring-2 focus:ring-inset focus:ring-slate-50 sm:text-sm sm:leading-6"
                            }
                            placeholder='Enter Password Here'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.errors.password && formik.touched.password && <MdOutlineErrorOutline className='text-[#ff0000] text-xl absolute right-2.5 top-2.5 ' />}
                    </div>

                    {formik.errors.password && formik.touched.password ?

                        <div className="mt-2 text-[#f80000] text-sm">
                            {formik.errors.password}
                        </div> :

                        null}

                    <div className="text-sm mt-3">
                        <Link to='/forget-password' className="font-semibold underline">
                            Forgot password?
                        </Link>
                    </div>
                </div>

                <ButtonLoader loading={loading} text='Login' />


            </form>
        </div>
    )
}

export default LoginPage
